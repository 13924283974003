import React from "react";
import { graphql } from "gatsby";
import Header from "../components/header";
import ReactHtmlParser from "react-html-parser";

const Voorstelling = (props) => {
  return (
    <div>
      {props.pageContext.langKey === "nl" && (
        <Header
          back="true"
          backLink="/voorstellingen"
          taal={props.pageContext.langKey}
        />
      )}
      {props.pageContext.langKey === "fr" && (
        <Header
          back="true"
          backLink="/fr/spectacles/"
          taal={props.pageContext.langKey}
        />
      )}
      {props.pageContext.langKey === "en" && (
        <Header
          back="true"
          backLink="/en/performances/"
          taal={props.pageContext.langKey}
        />
      )}
      <div>
        <img
          className="affiche_image"
          src={
            "https://www.circusronaldo.be/image.php?width=600&image=/uploads/" +
            props.data.allMysqlVoorstellingen.edges[0].node.mobile_affiche
          }
          alt=""
        />
        <div className="textContent">
          {ReactHtmlParser(
            props.data.allMysqlVoorstellingen.edges[0].node[
              "content_" + props.pageContext.langKey
            ].replace(/<a.+>/, "")
          )}
        </div>
      </div>
    </div>
  );
};

export default Voorstelling;

export const query = graphql`
  query ($ids: String!) {
    allMysqlVoorstellingen(filter: { id: { eq: $ids } }) {
      edges {
        node {
          id
          mobile_affiche
          content_nl
          content_fr
          content_en
        }
      }
    }
  }
`;
